<template>
  <div id="app">
    <main class="main">
      <div class="top">
          <div class="logo">
            <a href="http://donoancheio.it" target="_blank" rel="noopener noreferrer">
              <img class="logo-img" src="/logo.svg" />
              <img class="logo-arrow" src="/arrowLink.svg">
           </a>
          </div>
        <div class="instructions" v-html="$t('chooseLogo')">
        </div>
        <div class="link">
          <a href="http://donoancheio.it" target="_blank" rel="noopener noreferrer" v-html="$t('linkText')"></a>
        </div>
      </div>
      <div class="bottom">
        <div class="side">
          <p v-html="$t('sideText')"></p>
        </div>

        <div class="content">

          <div class="step-1" v-if="!gender">

            <div class="selector-wrap">

              <button type="button" class="btn" @click="setGender('female')">
                <img src="/select-female.svg" />
              </button>

              <span>{{ $t('or') }}</span>

              <button type="button" class="btn" @click="setGender('male')">
                <img src="/select-male.svg" />
              </button>

            </div>

          </div>

          <div class="step-2" v-if="gender" :class="['gender-' + gender]">

            <div class="uploader-wrap">

              <clipper-upload v-model="src" accept="image/x-png,image/gif,image/jpeg" class="uploader relative">
                <div class="absolute inset-0 flex flex-col justify-center items-center cursor-pointer uploader-inner border-4 border-dashed">
                  <div class="absolute text-2xl text-center" v-html="$t('clickToUpload') "></div>
                </div>
              </clipper-upload>

              <clipper-fixed v-if="src"  :src="src" :grid="false" ref="clipper" :min-scale="1" :area="100" bg-color="#212F59" />

              <div class="actions relative lg:absolute text-center w-full pt-4 flex" v-if="!resultVisible">
                <div>
                  <button type="button" class="bg-white text-blue uppercase text-xl font-bold px-4 py-1" @click="reset">{{ $t('reset') }}</button>
                </div>
                <div class="flex-grow ml-4">
                  <button v-if="!canTouch" :disabled="!src" type="button" class="w-full bg-red uppercase text-xl text-white font-bold px-2 py-1" @click="snapshot">
                    {{ $t('download') }}
                  </button>
                  <button v-else :disabled="!src" type="button" class="w-full bg-red uppercase text-xl text-white font-bold px-2 py-1" @click="showResult">
                    {{ $t('confirm') }}
                  </button>
                </div>
              </div>

              <div class="result absolute inset-0" v-show="resultVisible">
                <img src="" ref="result" style="width: 300px; height: auto; margin: 0 auto;" />
              </div>

              <div class="actions result-actions relative lg:absolute text-center pt-4 text-center" v-if="resultVisible">
                <p class="text-xl">{{ $t('avatarReady') }}</p>

                <button type="button" class="mt-4 bg-white text-blue uppercase text-xl font-bold px-4 py-1" @click="reset">
                  {{ $t('back') }}
                </button>
              </div>

            </div>
          </div> 

          <div class="preview hidden">
            <canvas ref="preview" />
          </div>
          <img src="/frame-ristora.png" ref="frame-male" class="hidden" />
          <img src="/frame-ristora.png" ref="frame-female" class="hidden" />
        </div>
      </div>
      
    </main>
    <footer class="footer">
      <div class="flex items-center mb-3">
        <a href="https://www.visitbergamo.net" target="_blank"><img src="/visitbergamo.svg" width="130" height="31" /></a>
        <img src="/turismobergamo.png" class="tall" />
      </div>
      <p>
        Copyright © {{ currentYear }} <br>
        Agenzia per lo sviluppo e la Promozione Turistica della Provincia di Bergamo scarl - Sede legale: Via T. Tasso 8 – 24121 Bergamo - Sede operativa: Viale Vittorio Emanuele II, 20 - 24121 Bergamo<br>
        C.F. e P.Iva 02910070164 e reg. imprese cciaa di Bergamo 02910070164 - <a target="_blank" href="http://www.ntnext.it/">Credits</a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  
  data() {
    return {
      src: '',
      gender: '',
      currentYear: new Date().getFullYear(),
      canTouch: 'ontouchstart' in window,
      resultVisible: false,
    }
  },

  mounted() {
    this.$refs.preview.width = 600
    this.$refs.preview.height = 600
  },

  methods: {
    reset() {
      this.src = ''
      this.gender = ''
      this.resultVisible = false
    },

    setGender(gender) {
      this.gender = gender
    },

    snapshot(_, returnImage = false) {
      const ctx = this.$refs.preview.getContext('2d')

      const imgEl = this.$refs.clipper.$el.querySelector('.img-translate img')
      const cropWidth = this.$refs.clipper.$el.querySelector('.wrap canvas').offsetWidth
      const ratio = imgEl.width / imgEl.height
      const previewWidth = this.$refs.preview.width
      const previewHeight = this.$refs.preview.height
      const translate = this.$refs.clipper.bgTL$
      const scale = this.$refs.clipper.bgWH$

      ctx.clearRect(0, 0, previewWidth, previewHeight);

      ctx.fillStyle = '#ffffff'
      ctx.fillRect(0, 0, previewWidth, previewHeight)

      const scaledWidth = imgEl.width * this.$refs.preview.width / cropWidth * scale
      const scaledHeight = scaledWidth / ratio

      const left = scaledWidth * translate.left / 100
      const top = scaledHeight * translate.top / 100

      const dx = previewWidth / 2 - scaledWidth / 2 + left
      const dy = previewHeight / 2 - scaledHeight / 2 + top
      ctx.drawImage(imgEl, dx, dy, scaledWidth, scaledHeight)

      ctx.drawImage(this.$refs['frame-' + this.gender], 0, 0, previewWidth, previewHeight)

      const imageData = this.$refs.preview.toDataURL('image/jpeg')
      if (returnImage) {
        return imageData
      }

      this.download(imageData, 'avatar.jpg')
    },

    download(url, filename) {
      const a = document.createElement('a')
      a.href = url
      a.setAttribute("download", filename)
      a.click()
    },

    showResult() {
      this.$refs.result.src = this.snapshot(null, true)
      this.resultVisible = true
    },
  },
}
</script>

<style lang="scss">
button:disabled {
  opacity: 0.4;
  cursor: not-allowed
}

#app {
  @apply flex flex-col min-h-screen font-body;
  background-image: url('/bergamascoancheio.jpg');
  background-position: center;
  background-size: cover;
}

.main {
  @apply bg-blue text-white p-4 mx-auto;
  box-sizing: border-box;
  margin-top: 320px;
  margin-bottom: 600px;

  @screen md {
    @apply w-3/4;
    margin-top: 20vw;
    margin-bottom: 40vw;
  }

  @screen lg {
    padding: 80px 65px;
    min-width: 1024px;
  }

  .top {
    @apply relative pt-20;

    @screen lg {
      @apply flex items-end pt-0;
    }

    .logo {
      .logo-img {
        width: 200px;
        height: auto;
        @apply mx-auto;

        @screen md {
          width: 400px;
        }

        @screen lg {
          @apply w-full;
        }
      }
      .logo-arrow {
        width: 200px;
        height: auto;
        padding-left: 70px;
        @apply mx-auto mt-2;

        @screen md {
          width: 400px;
          padding-left: 170px;
        }

        @screen lg {
          @apply w-full;
          padding-left: 40%;
        }
      }
    }

    .instructions {
      @apply flex-grow text-center text-xl pt-8 px-8;

      @screen lg {
        @apply pt-0 text-3xl ;
      }
    }

    .link {
      @apply absolute uppercase text-base font-bold border-2;
      top: 0;
      right: 0;
      border-color: #E6293E;

      @screen md {
        @apply text-lg;
      }
      @screen lg {
        transform: translateY(-50%);
      }

      a {
        @apply block px-5 py-1;
      }
    }
  }

  .bottom {
    @apply flex flex-col;

    @screen lg {
      @apply flex-row
    }

    .side {
      @apply text-xl py-16 order-2;
    
      @screen lg {
        @apply pb-0;
        padding-top: 75px;
        order: 0;
        width: 365px;
      }
    }

    .content {
      @apply flex-grow order-1 mt-4;

      @screen lg {
        @apply mt-0;
        padding-top: 75px;
      }

      .step-1 {
        @apply flex flex-col justify-start items-center h-full;

        .selector-wrap {
          @apply border-4 border-dashed flex flex-col justify-center items-center;
          width: 300px;
          height: 300px;
        }

        .btn {
          @apply p-4;
        }

        span {
          @apply my-5

          @screen lg {
            @apply my-10
          }
        }
      }

      .step-2 {
        @apply flex flex-col justify-start items-center h-full;
      
        .uploader-wrap {
          @apply relative mx-auto;

          .uploader {
            width: 300px;
            height: 300px;
          }

          .clipper-fixed {
            top: 0;
            left: 0;
            right: 0;
            height: 300px;
          }

          .cover .area {
            background-size: cover;
          }

          .actions {
            @screen lg {
              top: 100%;
            }
          }

          .result-actions {
            width: 300px;
          }

          .result {
            @apply mx-auto; 
            width: 300px;
          }
        }

        &.gender-male {
          .cover .area {
            background-image: url('/frame-ristora.png');
          }
        }

        &.gender-female {
          .cover .area {
            background-image: url('/frame-ristora.png');
          }
        }
      }
    }
  }
}

.footer {
  @apply  p-4;
  background-color: #F4F3EF;
  color: #AFAFAF;
  @apply text-xs font-bold;

  @screen lg {
    padding: 25px 65px;
  }

  img {
    height: 31px;
    width: auto;

    &.tall {
      height: 44px;
      margin-left: 2em;
    }
  }
}

.app-container {
  @apply relative h-screen flex flex-col justify-center items-center p-4;
  max-width: 80vmin;

  @screen md {
    max-width: 50vh;
  }
}
.clipper-fixed {
  @apply absolute
}

.uploader-container {
  display: flex;
  width: 100%;
  padding-top: 100%; 
}

.preview {
  @apply fixed;
  bottom: 30px;
  right: 30px;
  width: 300px;
  height: 300px;

  canvas {
    width: 100%;
    height: 100%;
  }
}
</style>
