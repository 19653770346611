export default {
  en: {
    chooseLogo: 'Choose a logo below and upload your photo to personalise it!',
    sideText: `
      <strong>I'll stay home</strong> too.<br>
      <strong>I'll commit</strong> too.<br>
      <strong>I'll share</strong> too.<br>
      <strong>I'll donate</strong> too.<br>
      <br>
      During this difficult time you cannot visit our wonderful territory, but you can and we can help our people to return to welcome you and meet you when we have won this long battle.
      <br>
      In the only way possible: <strong>together</strong>.<br>
      <br>
      <strong>Today we are even more Bergamasco,<br>
      today we are all Bergamasco.</strong>
    `,
    or: 'or',
    clickToUpload: 'Click here<br> to upload your photo',
    reset: 'Reset',
    confirm: 'Confirm',
    avatarReady: 'Your new avatar is ready! Press and hold on the image to download it to your device or share it.',
    back: 'Back',
  },
  it: {
    chooseLogo: 'Scegli un logo qui sotto<br>e carica la tua foto per personalizzarla!',
    linkText: 'Vai alla pagina per <span class="text-red">donare</span>',
    sideText: `
      In questo momento Bergamo è l’immagine della sofferenza, ma insieme possiamo farla diventare il simbolo della rinascita.
      <br>
      Da Bergamo all’Italia, dall’Europa al mondo: oggi siamo ancora più bergamaschi, oggi SIAMO TUTTI BERGAMASCHI.
      <br><br>
      Aiutaci a diffondere le raccolte fondi per l’emergenza sanitaria e l’aiuto socio economico del territorio bergamasco.
      <br><br>
      Come? è molto semplice: personalizza la tua immagine profilo di Facebook o carica una tua foto su Instagram con la cornice #bergamascoancheio. Clicca uno dei due loghi nel riquadro e segui le istruzioni.
      <br><br>
      Se vuoi puoi condividere il tuo post con questa frase:
      <br><br>
      <em>
      Bergamo si rialzerà, l’Italia si rialzerà #bergamoscoancheio #donoancheio #celafaremo #iorestoacasa
      </em>.
    `,
    or: 'oppure',
    clickToUpload: 'Clicca qui<br> per caricare la tua foto',
    reset: 'Reset',
    confirm: 'Conferma',
    avatarReady: 'Il tuo nuovo avatar è pronto! Tieni premuto sull\'immagine per scaricarla sul tuo dispositivo o condividerla.',
    back: 'Indietro',
  },
  fr: {
    chooseLogo: 'Choisissez un logo ci-dessous et téléchargez votre photo pour la personnaliser !',
    sideText: `
      Moi aussi, je reste chez moi.<br>
      Moi aussi, je m'engage.<br>
      Moi aussi, je partage.<br>
      Moi aussi, je donne.<br>
      <br>
      Dans cette période difficile, nous ne pouvons pas vous ouvrir notre territoire, mais ensemble nous pouvons faire en sorte d'aider la population à se retrouver afin qu'une fois cette longue bataille remportée elle puisse de nouveau vous accueillir.<br>
      <br>
      Une seule façon d'y parvenir... et ce sera ensemble.<br>
      <br>
      Aujourd'hui, sentons-nous tous bergamasques,<br>
      aujourd'hui, nous sommes tous bergamasques.
    `,
    or: 'Ou bien',
    clickToUpload: 'Cliquez ici<br> pour télécharger votre photo',
    reset: 'Remettre',
    confirm: 'Confirmer',
    avatarReady: 'Votre nouvel avatar est désormais prêt ! Maintenez l\'image pour la télécharger sur votre appareil ou pour la partager.',
    back: 'Précédent',
  },
  de: {
    chooseLogo: 'Wählen Sie unten ein Logo und laden Sie Ihr Foto hoch, um es zu personalisieren!',
    sideText: `
      Auch ich bleibe zu Hause.<br>
      Auch ich bemühe. <br>
      Auch ich teile.<br>
      Auch ich gebe.<br>
      <br>
      In diesen schwierigen Tagen können Sie unser wundervolles Gebiet nicht besuchen, aber Sie können und wir können unseren Leuten helfen, Sie wieder willkommen zu heißen und zu treffen, wenn wir diesen langen Kampf gewonnen haben.<br>
      <br>
      Auf die einzig mögliche Weise: zusammen.<br>
      <br>
      Heute sind wir noch mehr Bergamasker, heute sind wir alle Bergamasker.
    `,
    or: 'oder',
    clickToUpload: 'Klicken Sie hier,<br> um Ihr Foto hochzuladen',
    reset: 'Rücksetzen',
    confirm: 'Bestätigung',
    avatarReady: 'Ihr neuer Avatar ist fertig! Drücken Sie auf das Bild, um es auf Ihr Gerät herunterzuladen oder zu teilen.',
    back: 'Zurück',
  },
  es: {
    chooseLogo: '¡Elija un logotipo debajo y suba su foto para personalizarla!',
    sideText: `
      Yo también me quedo en casa.<br>
      Yo también me compromito.<br>
      Yo también comparto.<br>
      Yo también doy.<br>
      <br>
      En estos días difíciles no puedes visitar nuestra maravillosa tierra pero puedes y podemos ayudar a nuestra gente a volver darte la bienvenida cuando hayamos ganado esta larga batalla.<br>
      <br>
      De la única manera posible: juntos.<br>
      <br>
      Hoy somos aún más Bérgamo,<br>
      hoy todos somos de Bérgamo.
    `,
    or: 'o',
    clickToUpload: 'Haz clic aquí<br> para subir tu foto.',
    reset: 'Atrás',
    confirm: 'Confirmación',
    avatarReady: '¡Tu nuevo avatar está listo! Mantenga presionada la imagen para descargarla en su dispositivo o compartirla.',
    back: 'Atrás',
  },
  ru: {
    chooseLogo: 'Выбери лого из предложенных ниже и загрузи свое фото для персонализации!',
    sideText: `
      Я тоже остаюсь дома.<br>
      Я тоже прилагаю усилия.<br>
      Я тоже делюсь.<br>
      Я тоже вношу свой вклад.<br>
      <br>
      В эти трудные дни вы не сможете посетить нашу замечательную бергамскую землю, но вы можете и мы можем помочь нашему народу снова принимать гостей и встретиться с вами, когда эта долгая битва с вирусом будет выиграна.<br>
      <br>
      Единственно возможным способом - все вместе.<br>
      <br>
      Сегодня мы стали еще больше бергамцами,<br>
      сегодня мы все из Бергамо.
    `,
    or: 'или',
    clickToUpload: 'Перейди по ссылке для загрузки своего фото',
    reset: 'сброс',
    confirm: 'подтверждение',
    avatarReady: 'Твой новый юзерпик готов! Нажми и удерживай изображение, чтобы загрузить его на устройство или поделиться с другими.',
    back: 'Назад',
  }
}