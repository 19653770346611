import Vue from 'vue'
import App from './App.vue'
import VuejsClipper from 'vuejs-clipper'
import VueI18n from 'vue-i18n'
import messages from './messages'
import './assets/app.scss'
import { detectLocale } from './helpers'

Vue.config.productionTip = false

Vue.use(VuejsClipper)
Vue.use(VueI18n, {
  messages,
  locale: detectLocale(),
  fallbackLocale: 'en',
})

const i18n = new VueI18n({
  messages,
  locale: detectLocale(),
  fallbackLocale: 'en',
})

new Vue({
  render: h => h(App),
  i18n,
}).$mount('#app')
